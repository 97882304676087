import { gql } from '@apollo/client';


export const TESTIMONIALS = gql`
query {
    testimonials{
      data{
        id
        attributes{
          name
          job
          description
          image
        }
      }
    }
  } 
`;


export const PRODUCT_QUERY = gql`
query{
    products{
      data{
        id
          attributes{
          carousel
          benefit
          earn
          privileges
        }
      }
    }
  }
`

export const PRODUCT_INFO_QUERY = gql`
query{
    productInfos{
      data{
        id
          attributes{
            desktop_background
            mobile_background
            card
            heading
            desc
            offer
            privileges
            highlights
        }
      }
    }
  }
`

export const HEADER = gql`
query{
    header{
      data{
        id
          attributes{
            data
            desktop_text
            desktop_link
            mobile_text
            mobile_link
            cta_left
            cta_left_link
            data
        }
      }
    }
  }
`

export const FOOTER = gql`
query{
    footer{
      data{
        id
          attributes{
            data
            mobile_cta
            desktop_cta
            disclaimer
            location
            social
        }
      }
    }
  }
`

export const SCHOLARSHIP = gql`
query{
  scholarships{
    data{
      id
      attributes{
        info
        hero
        faq
        finalists_card
        scholars
        panelists
        timeline
        mission
        marquee
        visionary_leaders
        products
        qr
        testimonials
      }
    }
  }
}`

export const INTERNSHIP = gql`
query{
  internships{
    data{
      id
      attributes{
       faq
        deadline_text
        banner
        job_card
        steps
        faq
      }
    }
  }
}
`
export const INSURANCE = gql`
query{
  insurances{
    data{
      id
      attributes{
       renter
        health
        pet
        Navbar
      }
    }
  }
}
`

export const LOAN = gql`
query{
  loans{
    data{
      id
      attributes{
       auto
       Navbar
       testimonial
      }
    }
  }
}
`

export const HOME = gql`
query{
  homes{
    data{
      id
      attributes{
       banner
        testimonial
        marquee
        card
        feature_banner
        apply_now
      }
    }
  }
}`

export const EDGE = gql`
query{
  edges{
    data{
      id
      attributes{
        hero
        plans
        offerings
        testimonial
        blog
        faq
      }
    }
  }
}
`
export const VISA_ASSISTANCE = gql`
query{
  visaAssistances{
    data{
      id
      attributes{
        hero
        offerings
        blogs
      }
    }
  }
}`

export const TERMS_AND_CONDITIONS = gql`
query{
  termsAndConditions{
    data{
      id
      attributes{
        cashback_tnc
        esign_and_communications
        boost_tnc
        privacy_policy
        index
        credit_cardholder_agreement
        terms_of_use
      }
    }
  }
}
`
export const US_DEPARTURE = gql`
query{
  usDepartures{
    data{
      id
      attributes{
        hero
        card
        blogs
      }
    }
  }
}
`
export const REMITTANCE = gql`
query{
    remittances{
      data{
        id
        attributes{
          hero
          features
          timeline
          testimonial
          faq
          calculator
          footer
        }
      }
    }
}`

export const STUDENT_LOAN_QUERY = gql`
query{
    studentLoans {
      data {
        id
        attributes {
          pageData
          table
        }
      }
    }
  }
`

export const TOP_BANNER_QUERY = gql`
query {
  topBanners {
    data {
      attributes {
        data
      }
    }
  }
}
`

export const COOKIE_CONSENT_QUERY = gql`
query {
  cookieConsent {
    data {
      attributes {
        consentText
      }
    }
  }
}
`

export const HELP_QUERY = gql`
query{
  helps{
    data{
      id
      attributes{
        hero
        sidebar
        questionnaire
      }
    }
  }
}`

export const SIM_CARD_QUERY = gql`
query{
  simCards{
    data{
      id
      attributes{
        nav_data
        hero_data
        marquee_data
        benefit
        faq_data
        offerings
        community_data
        plan
      }
    }
  }
}`

export const PARTNERS = gql`
query{
  partners{
    data{
      id
      attributes{
        hero
        partner_data
      }
    }
  }
}`

export const ABOUT_US_QUERY = gql`
query{
  aboutUses{
    data{
      id
      attributes{
        hero
        team
        advisors
        investor
        awards
        blogs
      }
    }
  }
}`

export const BLOGS_QUERY = gql`
query blogs($page_url_slug: String){
  blogs(filters:{
    page_url_slug:{eq:$page_url_slug}
  }){
    data{
      id
      attributes{
        title
        subtitle
        duration
        description
        hero_image_url
        publishedAt
        authour{name,icon_url}
        quote{title,author}
        blog_categories{data{attributes{category}}}
        related_blogs{data{attributes{title,subtitle,duration,publishedAt,authour{name,icon_url},page_url_slug}}}
        description_2
        topics
      }
    }
  }
}
`
export const BLOGS_CATEGORIES = gql`
query blogCategories($category : String){
  blogCategories(filters:{
    category:{eq:$category}
  }){
    data{
      id
      attributes{
        category
        blogs{data{attributes{title,subtitle,duration,publishedAt,thumbnail_url,authour{name,icon_url},page_url_slug}}}
      }
    }
  }
}
`

export const BLOGS_LANDING = gql`
query{
  blogLandings{
    data{
      id
      attributes{
       popular{data{attributes{title,subtitle,duration,publishedAt,thumbnail_url,blog_categories{data{attributes{category}}},authour{name,icon_url},page_url_slug}}}
       featured{data{attributes{title,subtitle,duration,publishedAt,thumbnail_url,blog_categories{data{attributes{category}}},authour{name,icon_url},page_url_slug}}}
       news{data{attributes{title,subtitle,duration,thumbnail_url,publishedAt,blog_categories{data{attributes{category}}},authour{name,icon_url},page_url_slug}}}
      }
    }
  }
}
`
export const BLOGS_CATEGORIES_LIST = gql`
query{
  blogCategories{
    data{
      id
      attributes{
       category
       subtitle
       icon_url
      }
    }
  }
}
`

export const BLOGS_META_TAGS = gql`
query blogs($page_url_slug: String){
  blogs(filters:{
  page_url_slug:{eq:$page_url_slug}
  }){
    data{
      id
      attributes{
        meta{title,description}
        hero_image_url
      }
    }
  }
}
`

export const VISA_FOLDER_QUERY = gql`
query{
  visaFolders{
    data{
      attributes{
        data
      }
    }
  }
}
`

export const VISA_OFFERINGS_QUERY = gql`
query{
  visaOfferings{
    data{
      attributes{
        data
      }
    }
  }
}
`

export const VISA_SLOT_QUERY = gql`
query{
  visaSlots{
    data{
      attributes{
        data
      }
    }
  }
}
`

export const LOAN_REFINANCING_QUERY = gql`
query{
 loanRefinancings{
    data{
      attributes{
        data
      }
    }
  }
}`
