import React from 'react';
import * as Sentry from "@sentry/react";

import Layout from './src/components/Layout';
import { REDIRECTION_PATHS } from './src/constants/constants';
import { scrollToTop } from './src/helpers/utils';

import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/style.scss';

export const onClientEntry = () => {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [],
        ignoreErrors: [
            /UnknownError: Database deleted by request of the user/i,
            /UnknownError: Connection is closing./i,
            /Java exception was raised during method invocation/i,
            /AbortError: The transaction was aborted, so the request cannot be fulfilled./i,
            /UnknownError: Connection to Indexed Database server lost. Refresh the page to try again/i,
            /UnknownError: Attempt to open a cursor in database without an in-progress transaction/i,
            /Web push settings not configured on MoEngage dashboard/i,
            /Security Error: Blocked a frame with origin/i,
            /TypeError: /i,
        ],
        tracesSampleRate: 0.5,
        environment: process.env.REACT_APP_INSTANCE_NAME,
      });
}

export const wrapPageElement = ({ element, props }) => {
    return (
        <Layout {...props}>
            {element}
        </Layout>
    );
}

export const onRouteUpdate = ({ location }) => {
    const pathSplit = location?.pathname?.split('/');
    let redirect = false;
    pathSplit?.forEach(element => {
        if (REDIRECTION_PATHS.includes(element)) redirect = true;
    });

    setTimeout(() => {
        scrollToTop();
    },0);

    const searchParams = new URLSearchParams(window.location.search).toString();

    if (redirect) {
        const baseURL = process.env.NODE_ENV === 'development' ? 'stg-products.zolve.com' : 'products.zolve.com'
        const redirectionURL = searchParams ?
            `https://${baseURL}${location?.pathname}?${searchParams}` :
            `https://${baseURL}${location?.pathname}`;
        window.location.replace(redirectionURL);
    }
    setTimeout(() => {
        if (!window?.qr) {
            try {
                window.qr = window?.AF_SMART_SCRIPT?.displayQrCode('app-download-qr');
            } catch (e) {
                console.error(e);
            }
        }
    }, 2000);
}
