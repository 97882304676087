// react imports
import React, { useEffect, useState } from 'react';

// third party imports
import CookieConsent from './CookieConsent';
import Footer from './Footer/Footer';
import Header from './Header/Header';

// in-app imports
import { initFunnel } from '../marketing';
import { isBrowser } from './../helpers/utils';

export default function Layout({ children }) {
    const [hasAcceptedCookiePolicy, setHasAcceptedCookiePolicy] = useState(!!localStorage.getItem('cookieConsent'));

    const onContinue = () => {
        localStorage.setItem('cookieConsent', true);
        setHasAcceptedCookiePolicy(true);
        initFunnel();
    };

    const isTnCPage = () => isBrowser() && window?.location?.pathname?.includes('/terms-and-condition');

    const showConsent = () => (!hasAcceptedCookiePolicy && !isTnCPage());

    useEffect(() => {
        if (hasAcceptedCookiePolicy) {
            initFunnel();
        }
    }, []);

    return (
        <>
            {
                showConsent() &&
                <CookieConsent
                    onCookieConsentClick={onContinue}
                />
            }
            <div className={`layout ${showConsent() ? `layout--blur` : ''}`}>
                <Header />
                <div className="layout__body">
                    {children}
                </div>
                <Footer />
            </div>
        </>
    );
}
